import React, { useState, useEffect} from "react";
import { Navigation } from "../components/navigation";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

// data
import JsonData from "../data/data.json";

// styles
import "../styles/App.css";


const LandingPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div className="container-flex justify-center">
  
      <Navigation />    
      <Header data={landingPageData.Header} />

      <Footer />
    </div>
  );
};

export default LandingPage;