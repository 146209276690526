import React from 'react'
import Payment from '../components/payments';

import "../styles/App.css"
function PaymentPage() {
  return (
    <div className="container-fluid">
      <h1 className="text-center text-capitalize text-muted font-bold ">Payment</h1>

      <div className="content flex justify-center">
        <div className="paypal-options">
          <Payment/>
        </div>
      </div>
    <div className="disclaimer-text text-center text-muted">
      <p className='text-sm text-muted'>
        


      </p>
    </div>


    </div>
  );
}

export default PaymentPage;