import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LandingPage from "./pages/landingPage";
import BookingsPage from "./pages/bookingPage";
import ServicePage from "./pages/servicesPage";
import PaymentPage from "./pages/paymentPage";



import './styles/App.css';


export default function App(){

  return <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/bookings" element={<BookingsPage />} />
         <Route path="/services" element={<ServicePage />} />
         <Route path="/payments" element={<PaymentPage />} />
      </Routes>
    </BrowserRouter>;
};

