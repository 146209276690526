import React, { useState, useEffect } from "react";

import { Footer } from "../components/footer";
import { Navigation } from "../components/navigation";

// Styles & Scripts
import "../styles/formStyle.css";
import "../scripts/formScript";
 
const BookingsPage = () => {
  const [bookings, setBookings] = useState([]);
  const [faculty, setFaculty] = useState("");
  const [facultyList, setFacultyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [cell, setCell] = useState("");

  useEffect(() => {
    // TO DO: implement API call to fetch bookings data
    // For now, let's assume we have some sample data
    setTimeout(() => {
    const Bookings = [
      {id: Infinity,
        name: "",
      }
    ];
    const facultyList = {
      College_Business_Economics: "College of Business and Economics",
      Humanities: "Humanities",
      Faculty_of_Engineering_and_Built_environment : "Faculty of Engineering and Built environment ",
      Science : "Science",
      Health_Science : "Health Science", 
      Law : "Law",
      Faculty_of_Art_Design_and_Architecture: "Faculty_of_Art_Design_and_Architecture", 
      JBS : "JBS",
      Education : "Education"  


    };

    setBookings(Bookings);
    setFacultyList(facultyList);
    setLoading(false)
  },5000);
  }, []);
  
  const handleSubmit = e => {
    e.preventDefault();
    // TO DO: implement API call to create a new booking
    // For now, let's just log the booking details
    // console.log(
    //   `New booking: ${name}, ${email}, ${date}, ${time},${cell},${faculty}`
    // );
    // Clear the form fields
/*     setName("");
    setEmail("");
    setDate("");
    setTime("");
    setCell("");
    setFaculty("")
     */
  };

  return (
    <>
      <div className="container" >
        <Navigation />

       

        <div className="col-12" style={{ marginTop: "auto" }}> 
      
          <form
            id="form-container"
            className="container-flex"
            onSubmit={handleSubmit}
            style={{ marginTop: "15rem" }}
          >    <h6 className="text-center justify-center text-muted"> Bookings </h6>
            <h3 className="text-muted text-center"> Personal-Details </h3>
            <div className="row">
              <label className="d-block">
                Name:
                <input
                  size={75}
                  type="text"
                  placeholder="First Name"
                  value={name}
                  onChange={e => setName(e.target.value.trim())}
                />
              </label>
            </div>

            <div className="row">
              <label>
                E-mail:
                <input
                  size={75}
                  type="email"
                  placeholder="email@example.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </label>
            </div>

            <div className="row">
              <label>
                Cell:
                <input
                  size={75}
                  type="cell"
                  placeholder="+2778 778 7777"
                  value={cell}
                  onChange={e => setCell(e.target.value)}
                />
              </label>
            </div>

            <div className="row">
              <label>
                Date:
                <input
                  size={100}
                  type="date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
                />
              </label>
            </div>

            <div className="row">
              <label>
                Time:
                <input
                  size={75}
                  type="time"
                  value={time}
                  onChange={e => setTime(e.target.value)}
                />
              </label>
            </div>
        <h3 className="text-muted text-center"> Faculties & University </h3>
            <div className="row">
              <label>
                Faculty:
              <select value={faculty} onChange={e => setFaculty(e.target.value)}>

              <option id="selectItem" className="text-muted"  value=""> Select Your Faculty </option>
              {Object.values(facultyList).map(faculty => (
              <option key={faculty.id} value={faculty}>
                  {faculty} 
              </option>
              ))}
              </select>
              </label>
            </div>
                   <div className="row">
              <label>
                Service-Type:
            <select>
            <option id="selectItem" className="text-muted"  value=""> Choose Your Service </option>
          <optgroup label="UNDERGRAD_HONOURS">
            <option size={35} value="Full attire rental">Full attire rental</option>
            <option value="Belt only rental">Belt only rental</option>
            <option value="Bib only rental">Bib only rental</option>
            <option value="Hat only rental">Hat only rental</option>
          </optgroup>
          <optgroup label="MASTERS">
            <option value="Full masters attire rental">Full masters attire rental</option>
            <option value="Masters stole and hood rental">Masters stole and hood rental</option>
          </optgroup>
          <optgroup label="UNDERGRAD_HONOURS">
            <option value="Full attire purchase">Full attire purchase</option>
            <option value="Belt only purchase">Belt only purchase</option>
            <option value="Bib only purchase">Bib only purchase</option>
            <option value="Hat only purchase">Hat only purchase</option>
          </optgroup>
          <optgroup label="MASTERS">
            <option value="Full masters attire purchase">Full masters attire purchase</option>
            <option value="Masters stole and hood purchase">Masters stole and hood purchase</option>
          </optgroup>
            <option value="30min session">30min session</option>
            <option value="Lashes">Lashes</option>
            </select>
          </label>
      </div>
          <button 
          
          className="btn btn-block btn-success justify-center" type="submit" onClick={() => window.location.href = 'payments'} >
         
          Proceed To Payment
          </button>
          </form>         
      </div>

     
        <div className="container justify-center" style={{ paddingTop: "auto" }}>
          <h3 className="text-center">My Bookings</h3>      
      
          <div id="bookingsList" className="text-center justify-content-center">
              {loading ? (
            <h4>Checking & retrieving data from secured server...</h4>
      ) : (  bookings.map(booking =>
            <div key={booking.id}>
            <p>{booking.name} </p> {booking.email}  {booking.date}  {" "}
            {booking.time}  {booking.faculty}
            </div>
      ))      
        } <span className="text-center">
          <div className="flex text-center text-muted p-5">
          <h6 className="text-center text-danger">{bookings.length > 0 ? 'You currently have no bookings!' : ''}</h6> 
          </div>     
          </span>
          </div>
          </div>

        <div className="footer-container justify-center" style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
    </>
  );
};

export default BookingsPage;
