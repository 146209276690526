import React from "react"
  


const services = [
  {
    title: 'Accounting Science',
    icon: <span className="h-6 w-6" />,
    description: 'Description for Accounting Science',
    color: 'red',
  },
  {
    title: 'Agriculture & Environmental Sciences',
    icon: <span className="h-6 w-6" />,
    description: 'Description for Agriculture & Environmental Sciences',
    color: 'bg-orange-500',
  },
  {
    title: 'Economic & Management Sciences',
    icon: <span className="h-6 w-6" />,
    description: 'Description for Economic & Management Sciences',
    color: 'bg-yellow-500',
  },
  {
    title: 'Education',
    icon: <span className="h-6 w-6" />,
    description: 'Description for Education',
    color: 'bg-green-500',
  },
  {
    title: 'College of Graduates Studies',
    icon: <span className="h-6 w-6" />,
    description: 'Description for College of Graduates Studies',
    color: 'bg-blue-500',
  },
  {
    title: 'Human Sciences',
    icon: <span className="h-6 w-6" />,
    description: 'Description for Human Sciences',
    color: 'bg-indigo-500',
  },
  {
    title: 'Law',
    icon: <span className="h-6 w-6" />,
    description: 'Description for Law',
    color: 'bg-purple-500',
  },
  {
    title: 'Science, Engineering & Technology',
    icon: <span className="h-6 w-6" />,
    description: 'Description for Science, Engineering & Technology',
    color: 'bg-pink-500',
  },
];

export default function ServicePage() {
  return (
    <div className="flex container mx-auto py-12">
      <div className="grid grid-cols-12 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <div
            key={index}
            className={`rows p-4 rounded-lg ${
              index % 2 === 0 ? 'bg-red-500' : 'bg-blue-500'
            }`}
          >
            <div className="container-flex text-center mt-5">
              <div className="flex items-center space-x-2">
                {service.icon}
                <h3 className="text-md text-uppercase text-white">{service.title}</h3>
              </div>
            </div>
             <div className="container-flex text-center">
              <button className="btn text-muted font-bold text-uppercase  text-muted bg-orange-500 text-white font-bold py-2 px-4 rounded hover:bg-orange-700">
                Book Now 
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
