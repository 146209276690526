import React from "react";

export const Navigation = (props) => {

  return (
    <nav id="menu" className="navbar navbar-default bg-transparent navbar-fixed-top">
      <div className="container-flex">
        <div className=" navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
         <img src="img/md-logo.png" height="150%" alt="md_logo"></img>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/" className="page-scroll">
              MD Group (Pty) Ltd
              </a>
            </li>
            <li>
              <a href="https://protagonistclothing.meritdeeds.co.za" className="page-scroll">
              Protagonist-clothing
              </a>
            </li>
{/*             <li>
              <a href="/services" className="page-scroll">
              Services
              </a>
            </li> */}
            <li>
              <a href="/bookings" className="page-scroll">
              Bookings
              </a>
            </li>
       {/*      <li>
              <a href="/rentals" className="page-scroll">
              Rentals
              </a>
            </li> */}
     
        
          </ul>
        </div>
      </div>
    </nav>
  );
};
