import React from 'react';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div
      className="container-fluid text-center justify-center"
      style={{ marginTop: 'auto', backgroundColor:'#fff' }}
    >
      <p>
   
      </p>   
      <p>
        <small className="text-muted">
          Contact Us:
          <br />
          <a href="mailto:info@meritdeeds.co.za">info@meritdeeds.co.za</a>
          <br />
      
        </small>     
      </p>
      <ul className="social-media-list list-inline">
        <li>
          <a href="https://www.facebook.com/mdgowns" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/mdgowns" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitter" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/mdgowns" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-linkedin" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/mdgowns" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram" aria-hidden="true" />
          </a>
        </li>
      </ul>        
     <small className="text-default text-muted"> &copy; 
      {currentYear}</small> 

   <br />        
        <small className="text-muted text-white">Merit Deeds Group (Pty) Ltd</small>

    </div>
  );
};



