import React from "react";

export const Header = (props) => {
  return (
    <header id="header" className="container-flex justify-center">
      <div className="intro">
        <div className="overlay1 w-screen h-75vh" style={{height:'100vh', width:"auto",paddingTop:"15px"}}>
          <div className="container w-100 h-100">
            <div className="row">
              <div className="col-md col-md-offset intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p><i>{props.data ? props.data.paragraph : "Loading"}</i> </p>
                <a
                  href="/bookings"
                  className="btn btn-success btn-md page-scroll text-uppercase"
                >
                  Bookings
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
