import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';


import '../styles/paymentStyle.css'
const Payment = () => {
  const [payfastUrl, setPayfastUrl] = useState('');

  const handlePayFastPayment = () => {
    // Replace with your PayFast merchant details
    const merchantId = '';
    const merchantKey = 'your-merchant-key';
    const amount = '100.00'; // Example amount
    const itemName = 'Example Item';
    const returnUrl = 'https://your-return-url.com';
    const cancelUrl = 'https://your-cancel-url.com';

    const payfastForm = document.createElement('form');
    payfastForm.setAttribute('method', 'post');
    payfastForm.setAttribute('action', 'https://www.payfast.co.za/eng/process');

    const fields = {
      merchant_id: merchantId,
      merchant_key: merchantKey,
      amount: amount,
      item_name: itemName,
      return_url: returnUrl,
      cancel_url: cancelUrl,
    };

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', fields[key]);
        payfastForm.appendChild(hiddenField);
      }
    }

    document.body.appendChild(payfastForm);
    payfastForm.submit();
  };

  return (
    <div className="text-center flex-wrapper align-center">
    
      <PayPalScriptProvider options={{ "client-id": "PANJWKYXRX83C" }}>
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: '100.00' // Example amount
                }
              }]
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(details => {
              alert('Transaction completed by ' + details.payer.name.given_name);
            });
          }}
        />
      </PayPalScriptProvider>
      <div id="paypal-container-PANJWKYXRX83C"></div>
    </div>
  );
};

export default Payment;


